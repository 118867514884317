* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  cursor: pointer;
}

.Navigation {
  background-color: white;
  width: 100%;
  box-shadow: 10px 10px 7px whitesmoke;
}

.NavigationModal {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-color: white;
}

.NavigationModal .Head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 30px;
}

.NavigationModal .Head h2 {
  font-weight: 350;
}

.NavigationModal .Head span {
  width: 5%;
}

.NavigationModal .Head .delete {
  margin-right: 21px;
  font-weight: 100;
}

.NavigationModal .Content input[type="username"],
.NavigationModal .Content input[type="password"] {
  height: 60px;
  width: 90%;
  display: block;
  margin: 0 auto;
  background-color: white;
  color: black;
  border-color: transparent;
  outline-width: 1px;
  outline-color: black;
  padding-left: 20px;
  border-radius: 10px;
}

.Modal .Content input:not(:placeholder-shown) {
  background-color: white;
}

.NavigationModal .Content .Solution {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 25px;
  margin-left: 20px;
  margin-right: 21px;
}

.NavigationModal .Content button {
  width: 90%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-color: transparent;
  margin: 0 auto;
  background-color: #f8bd32;
  font-size: 17px;
  position: relative;
  border-radius: 10px;
}

.NavigationModal .Content button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 1px;
  width: 0%;
  height: 60px;
  background-color: #ffb4058d;
  transition: width 2s ease;
  border-radius: 10px;
}

.NavigationModal .Content button:hover:before {
  width: 100%;
}

.NavigationModal .Content span {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}

.NavigationModal .Content span p:nth-of-type(1) {
  color: #848484;
  font-weight: 300;
}

.NavigationModal .Content span p:nth-of-type(2) {
  padding-left: 20px;
  font-weight: 600;
}

.NavigationModal .Content span .WantReg {
  text-decoration: none;
  color: black;
  position: relative;
}

.NavigationModal .Content span .WantReg:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 20px;
  left: 2px;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.NavigationModal .Content span .WantReg:hover:before {
  width: 100%;
}

.NavigationModal .Content .ModalSocials {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  font-size: 20px;
}

.NavigationModal .Content .ModalSocials .Socials {
  margin-right: 20px;
  color: black;
}

/* Registeration Modal Css*/

.NavigationModal .Content2 {
  display: block;
  width: 90%;
  margin: 0 auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.NavigationModal .Content2 input {
  width: 100%;
  height: 60px;
  padding-left: 30px;
  margin-bottom: 20px;
  border-width: 1px;
  border-radius: 10px;
  outline-color: black;
}

.NavigationModal .Content2 select {
  height: 60px;
  width: 100%;
  padding-left: 30px;
  margin-bottom: 30px;
  border-width: 1px;
  border-radius: 10px;
}

.NavigationModal .Content2 p {
  margin-bottom: 30px;
}

.NavigationModal .Content2 button {
  width: 100%;
  height: 60px;
  border-color: transparent;
  background-color: #f8bd32;
  color: black;
  border-radius: 10px;
  font-size: 15px;
  margin-bottom: 30px;
  border-radius: 10px;
}

.NavigationModal .Content2 button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 600px;
  left: 41px;
  width: 0%;
  height: 60px;
  background-color: #ffb4058d;
  transition: width 2s ease;
  border-radius: 10px;
}

.NavigationModal .Content2 button:hover:before {
  width: 81%;
}

.NavigationModal .Content2 span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.NavigationModal .Content2 span .WantLog {
  padding-left: 10px;
  text-decoration: none;
  color: black;
  position: relative;
  font-weight: 550;
}

.NavigationModal .Content2 span .WantLog:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 20px;
  left: 10px;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.NavigationModal .Content2 span .WantLog:hover:before {
  width: 85%;
}

.Navigation .Container {
  width: 95%;
  background-color: white;
  color: black;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto;
  height: 100px;
}

.Navigation .Container .col {
  display: flex;
}

.Navigation .Container .col img {
  width: 40%;
}

.Navigation .Container .col:nth-of-type(2) {
  /* margin-top: 30px; */
  margin-left: -250px;
}

.Navigation .Container .col:nth-of-type(2) .Link {
  color: black;
  text-decoration: none;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding: 25px;
  position: relative;
}

.Navigation .Container .col:nth-of-type(2) .Link:before {
  content: "";
  position: absolute;
  top: 45px;
  left: 26px;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.Navigation .Container .col:nth-of-type(2) .Link:nth-of-type(1):hover:before {
  width: 50%;
}

.Navigation .Container .col:nth-of-type(3) {
  display: flex;
  justify-content: space-evenly;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Navigation .Container .col:nth-of-type(3) div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 10px;
}

.Navigation .Container .col:nth-of-type(3) div .Links {
  text-decoration: none;
  color: black;
  padding-left: 10px;
}

.Navigation .Container .col:nth-of-type(3) div .Links:hover {
  color: #f17e52;
}

.Navigation .Container .MenuIcon {
  display: none;
}

.Navigation .Container .col:nth-of-type(4) {
  display: none;
}

.Navigation .Container .Navigation-Menu {
  display: none;
}

/* Show hamburger icon and hide menu items on smaller screens */
@media (max-width: 1184px) {
  .Navigation .Container .col {
    display: none;
  }
  .Navigation .Container .MenuIcon {
    display: block;
    margin-right: 50px;
  }
  .Navigation .Container .col:nth-of-type(3) {
    display: none;
  }
  .Navigation .Container .col:nth-of-type(4) {
    display: block;
  }
  .Navigation .Container .Navigation-Menu {
    display: block;
    width: 100%;
    height: 350px;
    background-color: white;
    text-align: start;
    position: absolute;
    top: 11%;
    left: 0;
    padding-left: 20px;
    z-index: 2;
  }

  .Navigation .Container .Navigation-Menu div {
    margin-left: 10px;
    margin-top: 30px;
    height: 30px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }

  .Navigation .Container .Navigation-Menu div .Link {
    text-decoration: none;
    color: black;
  }
}

/* Banner Section Component Css */

.dot-container {
  background-color: transparent;
}

.dot-controls {
  background-color: black;
}

/* The Content Of The Hero Component */

.HeroContent {
  width: 80%;
  height: 250px;
  display: flex;
  margin: auto;
  position: absolute;
  top: 390px;
  left: 100px;
  background-color: transparent;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  /* opacity: 1; */
}

.HeroContent .Container {
  width: 100%;
  padding-top: 30px;
  padding-left: 40px;
  /* background-color: transparent; */
}

.HeroContent .Container h3 {
  color: white;
  font-size: 50px;
}

.HeroContent .Container .services {
  margin-top: 15px;
  /* margin-bottom: 10px; */
}

.HeroContent .Container .services button {
  border-color: transparent;
  width: 70px;
  height: 30px;
  margin: 1px;
  background-color: white;
  color: black;
}

.HeroContent .Container .Advanced-Bar {
  background-color: rgba(245, 249, 252, 0.8);
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100px;
  margin-top: -1px;
  margin-left: -40px;
  backdrop-filter: blur(10px);
}

.HeroContent .Container .Advanced-Bar div {
  border-style: solid;
  border-color: transparent;
  border-width: 2px;
  width: 22%;
  height: 50px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding-left: 50px;
  background-color: white;
}

.HeroContent .Container .Advanced-Bar div:nth-of-type(3) {
  border-color: transparent;
  background-color: #f8bd32;
  color: black;
  position: relative;
}

.HeroContent .Container .Advanced-Bar div:nth-of-type(3):before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 50px;
  background-color: #ffb3019a;
  transition: width 0.5s ease;
}

.HeroContent .Container .Advanced-Bar div:nth-of-type(3):hover:before {
  width: 100%;
}

.HeroContent .Container .Advanced-Bar div:nth-of-type(4) {
  border-color: transparent;
  background-color: #f8bd32;
  color: black;
  position: relative;
}

.HeroContent .Container .Advanced-Bar div:nth-of-type(4):before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 50px;
  background-color: #ffb3019a;
  transition: width 0.5s ease;
}

.HeroContent .Container .Advanced-Bar div:nth-of-type(4):hover:before {
  width: 100%;
}

.HeroContent .Container .dropdown {
  position: absolute;
  top: 267px;
  left: 18px;
  width: 22%;
  background-color: white;
  border-top-style: solid;
  border-top-color: rgb(207, 207, 207);
  border-top-width: 1px;
  padding-top: 5px;
  padding-left: 30px;
}

.HeroContent .Container .dropdown ul li {
  list-style-type: none;
  padding: 20px;
  cursor: pointer;
}

.HeroContent .Container .dropdown ul li:hover {
  color: brown;
  transition: all ease-in-out 0.1s;
}

.HeroContent .Container .dropdownLocation {
  position: absolute;
  top: 267px;
  left: 305px;
  width: 22%;
  background-color: white;
  border-top-style: solid;
  border-top-color: rgb(207, 207, 207);
  border-top-width: 1px;
  padding-top: 5px;
  padding-left: 30px;
}

.HeroContent .Container .dropdownLocation ul li {
  list-style-type: none;
  margin: 20px;
  cursor: pointer;
}

.HeroContent .Container .dropdownLocation ul li:hover {
  color: brown;
  transition: all ease-in-out 0.1s;
}

.HeroContent .Container .modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  /* Semi-transparent black overlay */
  display: flex;
  justify-content: center;
  align-items: center;
}

.HeroContent .Container .modal-content {
  background-color: #fff;
  padding: 30px;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  width: 70%;
  height: 60%;
  border-radius: 20px;
  overflow-y: scroll;
}

.HeroContent .Container .modal-content .modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.HeroContent .Container .modal-content .modal-header h4 {
  font-size: 25px;
  font-weight: 230;
}

.HeroContent .Container .modal-header button {
  border-color: transparent;
  width: 40px;
  font-size: 20px;
  font-weight: 50;
  background-color: white;
  font-family: "Courier New", Courier, monospace;
}

.HeroContent .Container .modal-details h5 {
  font-size: 22px;
  font-weight: 100;
  padding-top: 30px;
  padding-bottom: 15px;
}

.HeroContent .Container .modal-details .Contents {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}

.HeroContent .Container .modal-details .Contents div {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(210, 210, 210);
}

/* Type Dropdown Container */

.HeroContent .Container .modal-details .Type-Dropdown {
  background-color: whitesmoke;
  width: 302px;
  height: 200px;
  /* overflow-y: scroll; */
  padding-left: 20px;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 300px;
  /* top: 363px; */
}

.HeroContent .Container .modal-details .Type-Dropdown ul li {
  list-style-type: none;
  margin: 20px;
  cursor: pointer;
}

.HeroContent .Container .modal-details .Type-Dropdown ul li:hover {
  color: brown;
  transition: all ease-in-out 0.1s;
}

/* Type Dropdown Container */

.HeroContent .Container .modal-details .Apartment-Dropdown {
  background-color: whitesmoke;
  width: 302px;
  height: 250px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: 568px;
  top: 300px;
}

.HeroContent .Container .modal-details .Apartment-Dropdown ul li {
  list-style-type: none;
  margin: 20px;
  cursor: pointer;
}

.HeroContent .Container .modal-details .Apartment-Dropdown ul li:hover {
  color: brown;
  transition: all ease-in-out 0.1s;
}

/* Modal Location Dropdown Container */

.HeroContent .Container .modal-details .Location-Dropdown {
  background-color: whitesmoke;
  width: 302px;
  height: 270px;
  overflow-y: scroll;
  padding-left: 20px;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  left: 891px;
  /* top: 363px; */
  top: 300px;
}

.HeroContent .Container .modal-details .Location-Dropdown ul li {
  list-style-type: none;
  margin: 20px;
  cursor: pointer;
}

.HeroContent .Container .modal-details .Location-Dropdown ul li:hover {
  color: brown;
  transition: all ease-in-out 0.1s;
}

/* The Property Size Section */

.HeroContent .Container .modal-details .Second-Content {
  width: 100%;
  background-color: white;
}

.HeroContent .Container .modal-details .Second-Content .Container {
  /* display: grid;
    grid-template-columns: repeat(5,1fr);
    gap: 20px; */
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-top: -30px;
}

.HeroContent .Container .modal-details .Second-Content .Container .First {
  width: 90%;
  display: flex;
  justify-content: space-around;
}

.HeroContent .Container .modal-details .Second-Content .Container .First div {
  width: 100%;
  /* background-color: red; */
  margin-left: -38px;
  /* margin: 1px; */
}

.HeroContent
  .Container
  .modal-details
  .Second-Content
  .Container
  .First
  div:nth-of-type(2) {
  margin-left: 1px;
}

.HeroContent
  .Container
  .modal-details
  .Second-Content
  .Container
  .First
  div
  input {
  width: 305px;
  height: 50px;
  padding-left: 30px;
  color: black;
  outline: black;
  /* gap: 10px; */
}

.HeroContent .Container .modal-details .Second-Content .Container .Second {
  width: 40%;
  display: flex;
  justify-content: space-around;
}

.HeroContent .Container .modal-details .Second-Content .Container .Second div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.HeroContent
  .Container
  .modal-details
  .Second-Content
  .Container
  .Second
  div
  input {
  width: 50px;
  height: 50px;
  margin-left: 10px;
  outline: black;
  text-transform: capitalize;
  padding-left: 15px;
}

.HeroContent .Container .modal-details .Second-Content .ProgressContainer {
  width: 100%;
  margin: auto;
  padding-top: 30px;
}

.HeroContent
  .Container
  .modal-details
  .Second-Content
  .ProgressContainer
  .progress-bar
  input {
  width: 100%;
}

.HeroContent
  .Container
  .modal-details
  .Second-Content
  .ProgressContainer
  .progress-bar
  .price {
  text-align: right;
}

/* Amenities Css Section */

.HeroContent .Container .modal-details .Amenities {
  padding-top: 30px;
}

.HeroContent .Container .modal-details .Amenities .Container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  padding-bottom: 30px;
}

.HeroContent .Container .modal-details .Amenities .Container .col div {
  display: flex;
  align-items: center;
  margin: 10px;
}

.HeroContent .Container .modal-details .Amenities .Container .col div p {
  padding-left: 10px;
}

.HeroContent .Container .modal-details .Amenities .Button-Container {
  margin-top: 30px;
  display: flex;
  justify-content: space-between;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(1)
  button {
  display: flex;
  align-items: center;
  text-align: center;
  height: 60px;
  width: 180px;
  border-color: transparent;
  padding-left: 18px;
  font-size: 15px;
  background-color: #f8bd32;
  border-radius: 10px;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(1)
  button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 557px;
  left: 1;
  width: 0%;
  height: 58px;
  border-radius: 10px;
  background-color: #ffb4049d;
  transition: width 0.5s ease;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(1)
  button:hover:before {
  width: 11%;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(1)
  button
  p {
  padding-left: 10px;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(2) {
  display: flex;
  padding-top: 25px;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(2)
  button {
  width: 80px;
  height: 35px;
  display: flex;
  align-items: center;
  padding-left: 5px;
  margin: 5px;
  border-color: transparent;
}

.HeroContent
  .Container
  .modal-details
  .Amenities
  .Button-Container
  .col:nth-of-type(2)
  button
  p {
  padding-left: 10px;
}

/* Banner Section On Small Screens */
@media (max-width: 995px) {
  .HeroContent {
    height: 500px;
    width: 95%;
    position: absolute;
    top: 100px;
    left: 10px;
    font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
      sans-serif;
  }

  .HeroContent .Container {
    padding-top: 200px;
    padding-left: 5px;
  }

  .HeroContent .Container h3 {
    color: white;
    font-size: 35px;
  }

  .HeroContent .Container .Advanced-Bar {
    display: none;
  }
}

/* Choices Components Css */

.Choices {
  width: 100%;
  background-color: white;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Choices h4 {
  padding-left: 100px;
  padding-top: 50px;
  font-size: 55px;
  font-weight: 350;
  padding-bottom: 50px;
}

.Choices .Container {
  width: 85%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: auto;
  padding-bottom: 40px;
}

.Choices .Container .Card {
  width: 100%;
  background-color: white;
}

.Choices .Container .Card img {
  width: 100%;
  object-fit: cover;
  height: 300px;
  display: flex;
  margin: auto;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.Choices .Container .Card .Action {
  background-color: rgba(248, 232, 232, 0.305);
  opacity: 1;
  width: 80px;
  height: 30px;
  position: absolute;
  top: 1050px;
  left: 115px;
  border-color: transparent;
  color: white;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.Choices .Container .Card .Location {
  display: flex;
  margin-top: 15px;
  margin-bottom: 20px;
}

.Choices .Container .Card .Location p {
  padding-left: 15px;
}

.Choices .Container .Card .Location p span:hover {
  color: #fba887;
}

.Choices .Container .Card .Name {
  font-size: 25px;
  padding-bottom: 20px;
  text-decoration: none;
  position: relative;
}

.Choices .Container .Card .Name:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 30px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.Choices .Container .Card .Name:hover:before {
  width: 45%;
}

/* Name 3 Css */
.Choices .Container .Card .Name3 {
  font-size: 25px;
  padding-bottom: 20px;
  text-decoration: none;
  position: relative;
}

.Choices .Container .Card .Name3:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 30px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.Choices .Container .Card .Name3:hover:before {
  width: 56%;
}

/* End Of Nam */
.Choices .Container .Card .Description {
  margin-bottom: 20px;
}

.Choices .Container .Card .Main {
  display: flex;
  align-items: center;
}

.Choices .Container .Card .Main .Price {
  padding-top: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 350;
  font-size: 25px;
}

.Choices .Container .Card .Main .Contents {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-left: 30px;
}

.Choices .Container .Card .Main .Contents div {
  display: flex;
  margin-right: 20px;
}

.Choices .Container .Card .Main .Contents div p {
  padding-left: 10px;
}

.Choices .Browse {
  background-color: white;
  width: 100%;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Choices .Browse button {
  height: 60px;
  width: 230px;
  border-color: transparent;
  background-color: #f17e52;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 15px;
  border-radius: 10px;
  position: relative;
}

.Choices .Browse button .Text {
  position: absolute;
  top: 20px;
  left: 40px;
  text-decoration: none;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Choices .Browse button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 60px;
  border-radius: 10px;
  background-color: #ed5f2785;
  transition: width 0.5s ease;
}

.Choices .Browse button:hover:before {
  width: 100%;
}

/* Small Screen Style Of The Choices Section */
@media (max-width: 995px) {
  .Choices h4 {
    font-size: 35px;
    padding-left: 30px;
  }

  .Choices .Container {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 50px;
    margin: 0 auto;
  }
}

@media (max-width: 700px) {
  .Choices .Container {
    width: 95%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
    margin: 0 auto;
  }

  .Choices .Container .Card img {
    object-fit: cover;
  }
}

/* Spaces Component Css */

.Spaces {
  background-color: #edf9f9;
  height: 520px;
  width: 100%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Spaces .Container {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: auto;
}

.Spaces .Container .col {
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
}

.Spaces .Container .col:nth-of-type(1) div {
  height: 350px;
  width: 540px;
  margin-left: -18px;
  background-color: #f8bd32;
  border-radius: 15px;
  z-index: 10;
}

.Spaces .Container .col:nth-of-type(2) {
  margin-left: 30px;
}

.Spaces .Container .col p {
  font-size: 20px;
}

.Spaces .Container .col img {
  width: 550px;
  height: 350px;
  object-fit: cover;
  border-radius: 15px;
  position: absolute;
  margin-top: -335px;
}

.Spaces .Container .col h4 {
  padding-top: 5px;
  font-size: 30px;
  padding-bottom: 20px;
}

.Spaces .Container .col .Services {
  display: block;
}

.Spaces .Container .col .Services p {
  margin-top: 20px;
  font-size: 17px;
}

.Spaces .Container .col .Services button {
  border-color: transparent;
  margin-top: 25px;
  height: 55px;
  width: 200px;
  background-color: #f8bd32;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  border-radius: 5px;
  position: relative;
}

.Spaces .Container .col .Services button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 55px;
  background-color: #ffb4049e;
  transition: width 0.5s ease;
}

.Spaces .Container .col .Services button:hover:before {
  width: 100%;
}

/* Small Screen Design For The Spaces Component */
@media (max-width: 995px) {
  .Spaces {
    display: none;
  }
}

/* Perfect Component Css */

.Perfect {
  width: 100%;
  height: 400px;
  padding-top: 150px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Perfect h2 {
  padding-left: 150px;
  font-size: 35px;
  padding-bottom: 80px;
  font-weight: 350;
}

.Perfect .Container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  width: 80%;
  margin: auto;
  padding-bottom: 100px;
}

.Perfect .Container .col {
  border-right-style: solid;
  border-right-color: black;
  border-right-width: 1px;
  padding-left: 10px;
  padding-right: 30px;
}

.Perfect .Container .col:nth-of-type(4) {
  border-right-color: transparent;
}

.Perfect .Container .col img {
  margin-bottom: 10px;
  /* width: 40%; */
}

.Perfect .Container .col h3 {
  padding-bottom: 20px;
}

/* Small Screen Design For The Perfect Component */
@media (max-width: 995px) {
  .Perfect {
    height: 700px;
  }

  .Perfect h2 {
    padding-left: 30px;
  }

  .Perfect .Container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }

  .Perfect .Container .col:nth-of-type(2) {
    border-right-style: none;
  }

  .Perfect .Container .col:nth-of-type(4) {
    border-right-style: none;
  }
}

@media (max-width: 700px) {
  .Perfect {
    height: 100%;
  }

  .Perfect .Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .Perfect .Container .col {
    border-right-style: none;
  }
}

/* Discover Component Css */
.Discover {
  width: 100%;
  height: 700px;
  background-image: url("https://images.pexels.com/photos/5563472/pexels-photo-5563472.jpeg");
  background-position: center;
  background-size: cover;
  margin-top: 200px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Discover .Container {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto;
  padding-top: 50px;
}

.Discover .Container .col:nth-of-type(1) {
  padding-top: 250px;
  color: rgb(255, 255, 255);
  padding-left: 20px;
}

.Discover .Container .col:nth-of-type(1) h3 {
  font-size: 50px;
  padding-bottom: 30px;
  font-weight: 350;
}

.Discover .Container .col:nth-of-type(1) p {
  font-size: 20px;
  font-weight: 100;
  line-height: 1.1;
}

.Discover .Container .col:nth-of-type(2) {
  height: 630px;
  width: 90%;
  background-color: rgba(232, 232, 232, 0.847);
  padding: 50px;
  border-radius: 15px;
}

.Discover .Container .col:nth-of-type(2) h3 {
  font-size: 32px;
  font-weight: 480;
}

.Discover .Container .col:nth-of-type(2) p {
  font-size: 20px;
  font-weight: 100;
  padding-top: 30px;
}

.Discover .Container .col:nth-of-type(2) form {
  display: block;
  margin-top: 60px;
}

.Discover .Container .col:nth-of-type(2) form input {
  width: 100%;
  height: 60px;
  padding-left: 20px;
  margin-bottom: 30px;
  outline: none;
  color: black;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 18px;
  border-color: transparent;
  background-color: rgb(255, 255, 255);
}

.Discover .Container .col:nth-of-type(2) button {
  height: 60px;
  width: 200px;
  border-color: transparent;
  background-color: #f17e52;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  border-radius: 10px;
  position: relative;
}

.Discover .Container .col:nth-of-type(2) button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 60px;
  background-color: #f17f5269;
  color: white;
  transition: width 0.5s ease;
  border-radius: 10px;
}

.Discover .Container .col:nth-of-type(2) button:hover:before {
  width: 100%;
}

/*  Small Screen Design Of The Discovery Component */
@media (max-width: 995px) {
  .Discover {
    width: 100%;
    height: 100%;
    padding-bottom: 50px;
  }

  .Discover .Container {
    width: 90%;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 30px;
  }

  .Discover .Container .col:nth-of-type(1) {
    padding-top: 50px;
  }

  .Discover .Container .col:nth-of-type(2) {
    width: 100%;
  }
}

/* Renovation Component Css */

.Renovation {
  width: 100%;
  height: 700px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding-left: 150px;
  background-color: white;
  margin-bottom: 150px;
}

.Renovation h3 {
  font-size: 35px;
  padding-top: 150px;
}

.Renovation strong {
  font-size: 36px;
  font-weight: 750;
}

.Renovation .Container {
  margin-top: 50px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

/* Renovation First Left Grid Of Renovation Component */

.Renovation .Container .col:nth-of-type(1) {
  display: block;
}

.Renovation .Container .col:nth-of-type(1) .Table:nth-of-type(1) {
  margin-left: -18px;
}

.Renovation .Container .col:nth-of-type(1) .Table {
  display: flex;
  margin-bottom: 20px;
}

.Renovation .Container .col:nth-of-type(1) .Table:nth-of-type(1) #TableRight {
  margin-left: -15px;
}

.Renovation .Container .col:nth-of-type(1) .Table .TableRight {
  margin-left: 10px;
}

.Renovation .Container .col:nth-of-type(1) .Table .TableRight h3 {
  padding-top: 18px;
  font-size: 25px;
  font-weight: 350;
  padding-bottom: 15px;
}

/* Renovation Second Right Grid For Renovation Component */

.Renovation .Container .col:nth-of-type(2) {
  width: 100%;
}

.Renovation .Container .col:nth-of-type(2) .Actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 100%;
  padding-right: 130px;
}

.Renovation .Container .col:nth-of-type(2) .Actions .First img {
  height: 445px;
  border-radius: 15px;
}

.Renovation .Container .col:nth-of-type(2) .Actions .Second img {
  height: 213px;
  border-radius: 15px;
}

.Renovation .Container .col:nth-of-type(2) .Actions .Second img:nth-of-type(2) {
  margin-top: 15px;
}

/*  Small Screen Design Of The Renovation Component */
@media (max-width: 995px) {
  .Renovation {
    width: 100%;
    height: 100%;
    padding-left: 0;
  }

  .Renovation .Heading {
    margin-left: 30px;
    margin-bottom: 30px;
  }

  .Renovation .Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 95%;
    margin: 0 auto;
  }

  .Renovation .Container .col:nth-of-type(2) .Actions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 100%;
  }

  .Renovation .Container .col:nth-of-type(2) .Actions .First {
    width: 100%;
    display: none;
  }

  .Renovation .Container .col:nth-of-type(2) .Actions .Second {
    width: 100%;
    display: none;
  }
}

/* Loan Component Css */

.Loan {
  width: 100%;
  height: 500px;
  background-color: #edf9f9;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Loan .Container {
  width: 85%;
  height: 500px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin: 0 auto;
  padding-top: 70px;
  padding-bottom: 70px;
}

.Loan .Container .col:nth-of-type(1) {
  position: relative;
}

.Loan .Container .col div {
  height: 100%;
  width: 90%;
  background-color: #f8bd32;
  border-radius: 15px;
  margin-left: -20px;
}

.Loan .Container .col img {
  width: 90%;
  height: 100%;
  border-radius: 15px;
  margin-top: -340px;
  position: absolute;
}

.Loan .Container .col h2 {
  font-size: 40px;
  padding-bottom: 30px;
}

.Loan .Container .col h2 strong {
  font-weight: 750;
}

.Loan .Container .col p {
  font-size: 18px;
  color: black;
  line-height: 1.1;
}

.Loan .Container .col button {
  height: 60px;
  width: 230px;
  color: black;
  background-color: #a9de74;
  border-color: transparent;
  margin-top: 40px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  border-radius: 15px;
  position: relative;
}

.Loan .Container .col button .Link {
  color: white;
  text-decoration: none;
}

.Loan .Container .col button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 60px;
  border-radius: 15px;
  background-color: #98f04188;
  transition: width 0.5s ease;
  overflow: hidden;
}

.Loan .Container .col button:hover:before {
  width: 100%;
}

/*  Small Screen Design Of The Loan Component */
@media (max-width: 995px) {
  .Loan {
    height: 100%;
  }
  .Loan .Container {
    width: 100%;
    height: 100%;
    padding-left: 30px;
    padding-right: 10px;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .Loan .Container .col:nth-of-type(1) {
    display: none;
  }

  .Loan .Container .col:nth-of-type(2) {
    width: 100%;
  }
}

/* Newsletter Component Css */

.NewsLetter {
  width: 100%;
  height: 540px;
  background-color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: none;
}

.NewsLetter img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.NewsLetter .Container {
  position: absolute;
  margin-top: -370px;
  padding-left: 200px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
}

.NewsLetter .Container .col:nth-of-type(1) h3 {
  color: white;
  font-size: 50px;
  font-weight: 450;
  padding-bottom: 30px;
}

.NewsLetter .Container .col:nth-of-type(1) p {
  color: white;
  font-size: 20px;
}

.NewsLetter .Container .col:nth-of-type(2) {
  background-color: rgba(255, 255, 255, 0.551);
  width: 500px;
  height: 320px;
  margin-top: -100px;
  padding-top: 50px;
  padding-left: 50px;
  border-radius: 10px;
}

.NewsLetter .Container .col:nth-of-type(2) h5 {
  font-size: 35px;
  padding-bottom: 30px;
  font-weight: 500;
}

.NewsLetter .Container .col:nth-of-type(2) p {
  font-size: 18px;
}

.NewsLetter .Container .col:nth-of-type(2) div {
  margin-top: 50px;
  display: flex;
}

.NewsLetter .Container .col:nth-of-type(2) div input {
  height: 60px;
  width: 60%;
  padding-left: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 17px;
  color: black;
  outline: black;
  border-width: 1px;
  border-color: transparent;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.NewsLetter .Container .col:nth-of-type(2) div button {
  height: 60px;
  width: 30%;
  border-color: transparent;
  background-color: #f17e52;
  color: white;
  font-size: 17px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  position: relative;
}

.NewsLetter .Container .col:nth-of-type(2) div button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -2px;
  left: 0;
  width: 0%;
  height: 60px;
  background-color: #e15a2429;
  transition: width 0.5s ease;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.NewsLetter .Container .col:nth-of-type(2) div button:hover:before {
  width: 101%;
}

/* Blog Component Css */

.Blog {
  width: 100%;
  height: 1000px;
  background-color: white;
  padding-top: 150px;
  margin-bottom: 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: none;
}

.Blog h1 {
  padding-left: 145px;
  font-size: 45px;
  font-weight: 350;
  padding-bottom: 70px;
}

.Blog .Container {
  width: 80%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin: 0 auto;
}

.Blog .Container .Card {
  width: 100%;
  height: 400px;
  height: 80%;
  margin-top: -50px;
}

.Blog .Container .Card img {
  height: 100%;
  width: 100%;
  object-fit: contain;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.Blog .Container .Card span {
  display: flex;
  padding-bottom: 30px;
  cursor: pointer;
  margin-top: -50px;
}

.Blog .Container .Card span p:nth-of-type(1) {
  padding-right: 5px;
}

.Blog .Container .Card span p:nth-of-type(1):hover {
  color: #fba887;
}

.Blog .Container .Card span p:nth-of-type(2) {
  padding-left: 5px;
}

.Blog .Container .Card span p:nth-of-type(2):hover {
  color: #fba887;
}

.Blog .Container .Card h4 {
  font-size: 34px;
  font-weight: 450;
  text-decoration: none;
  position: relative;
}

.Blog .Container .Card h4:nth-of-type(2) {
  padding-bottom: 20px;
}

.Blog .Container .Card h4:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 35px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.Blog .Container .Card h4:hover:before {
  width: 80%;
}

.Blog .Container .Card p {
  font-size: 17px;
}

.Blog .Container .Card h6 {
  padding-top: 30px;
  font-size: 18px;
  font-weight: 350;
  text-decoration: none;
  position: relative;
}

.Blog .Container .Card h6:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 52px;
  left: 0;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.Blog .Container .Card h6:hover:before {
  width: 21%;
}

/* Footer Component Css */

.Footer {
  width: 100%;
  height: 450px;
  background-color: #fafafa;
  color: #848484;
  padding-top: 50px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.Footer .Container {
  width: 80%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-bottom: 80px;
}

.Footer .Container .col:nth-of-type(1) img {
  width: 180px;
  margin-bottom: 20px;
}

.Footer .Container .col:nth-of-type(1) p {
  font-size: 18px;
}

.Footer .Container .col:nth-of-type(2) {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 50px;
  padding-top: 55px;
}

.Footer .Container .col:nth-of-type(2) .First h5 {
  color: black;
  font-size: 20px;
  font-weight: 350;
  padding-bottom: 20px;
}

.Footer .Container .col:nth-of-type(2) .First li {
  list-style-type: none;
  font-size: 17px;
  padding-bottom: 10px;
}

.Footer .Container .col:nth-of-type(2) .First li:hover {
  color: #f17e52;
  transition: all 0.1s ease-in-out;
}

.Footer .Container .col:nth-of-type(2) .Second h5 {
  color: black;
  font-size: 20px;
  font-weight: 350;
  padding-bottom: 20px;
}

.Footer .Container .col:nth-of-type(2) .Second li {
  list-style-type: none;
  font-size: 17px;
  padding-bottom: 10px;
}

.Footer .Container .col:nth-of-type(2) .Second li:hover {
  color: #f17e52;
  transition: all 0.1s ease-in-out;
}

.Footer .Container .col:nth-of-type(2) .Third h5 {
  color: black;
  font-size: 20px;
  font-weight: 350;
  padding-bottom: 20px;
}

.Footer .Container .col:nth-of-type(2) .Third li {
  list-style-type: none;
  font-size: 17px;
  padding-bottom: 10px;
}

.Footer .Container .col:nth-of-type(2) .Third li:hover {
  color: #f17e52;
  transition: all 0.1s ease-in-out;
}

.Footer .Bottom {
  padding-top: 30px;
  padding-bottom: 40px;
  width: 80%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 40px;
}

.Footer .Bottom .Left p {
  display: flex;
}

.Footer .Bottom .Left p span {
  padding-left: 5px;
}

.Footer .Bottom .Left p li {
  padding-left: 5px;
  list-style-type: none;
}

.Footer .Bottom .Left p li:hover {
  color: #f17e52;
}

.Footer .Bottom .Left p ins {
  padding-right: 5px;
}

.Footer .Bottom .Right {
  display: flex;
  justify-content: space-around;
  font-size: 17px;
}

.Footer .Bottom .Right p {
  font-weight: 600;
  color: black;
  padding-right: 25px;
  display: flex;
}

.Footer .Bottom .Right li {
  list-style-type: none;
  padding-right: 40px;
  font-size: 17px;
}

.StarIcon:hover {
  color: black;
}

/* Footer Design On Small Screen */

@media (max-width: 995px) {
  .Footer {
    height: 100%;
  }
  .Footer .Container {
    width: 90%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
  }

  .Footer .Container .col:nth-of-type(2) {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-area: 20px;
  }

  .Footer .Bottom {
    display: block;
    width: 90%;
    padding-bottom: 40px;
  }

  .Footer .Bottom .Right {
    display: block;
    padding-top: 30px;
  }

  .Footer .Bottom .Right p {
    margin-bottom: 10px;
  }

  .Footer .Bottom .Right li {
    padding-bottom: 10px;
  }
}

/* About Us Page Css */

.AboutUs {
  width: 100%;
  background-color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.AboutUs .Container .Hero {
  width: 100%;
  height: 550px;
  background-color: white;
}

.AboutUs .Container .Hero img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AboutUs .Container .Hero h1 {
  margin-top: -360px;
  margin-left: 160px;
  color: white;
  font-size: 45px;
  font-weight: 350;
}

.AboutUs .Container .Banner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding: 100px;
}

.AboutUs .Container .Banner .col img {
  width: 90%;
  height: 100%;
  border-radius: 15px;
}

.AboutUs .Container .Banner .col h4 {
  font-size: 35px;
  font-weight: 550;
  padding-bottom: 20px;
}

.AboutUs .Container .Banner .col p {
  font-size: 16px;
  line-height: 1.9;
}

.AboutUs .Container .Banner .col button {
  background-color: #f8bd32;
  color: black;
  height: 60px;
  width: 150px;
  margin-top: 40px;
  border-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.AboutUs .Container .Banner .col button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 988px;
  left: 850px;
  width: 0%;
  height: 60px;
  background-color: #ffb4058d;
  transition: width 2s ease;
  border-radius: 10px;
}

.AboutUs .Container .Banner .col button:hover:before {
  width: 9%;
}

.AboutUs .Container .Statistics {
  width: 100%;
  height: 300px;
  background-color: aliceblue;
}

.AboutUs .Container .Statistics .Container {
  width: 90%;
  height: 100%;
  display: grid;
  place-items: center;
  /* Centers the grid items both vertically and horizontally */
  grid-template-columns: repeat(5, 1fr);
  gap: 20px;
  text-align: center;
  /* Centers the text horizontally within each grid item */
  margin: 0 auto;
  background-color: aliceblue;
  color: black;
}

.AboutUs .Container .Statistics .Container div h3 {
  font-size: 35px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.AboutUs .Container .Statistics .Container div p {
  font-size: 16px;
  font-weight: 480;
}

.AboutUs .Container .Mission {
  width: 100%;
  height: 500px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.AboutUs .Container .Mission .Container {
  width: 80%;
  height: 100%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  padding: 50px;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(1) {
  text-align: start;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(1) h3 {
  font-size: 35px;
  padding-bottom: 20px;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(1) p {
  font-size: 16px;
  line-height: 1.9;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(1) button {
  background-color: #f8bd32;
  color: black;
  height: 60px;
  width: 150px;
  margin-top: 40px;
  border-color: transparent;
  border-radius: 10px;
  font-size: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(1) button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 1770px;
  left: 665px;
  width: 0%;
  height: 60px;
  background-color: #ffb4058d;
  transition: width 2s ease;
  border-radius: 10px;
}

.AboutUs
  .Container
  .Mission
  .Container
  .col:nth-of-type(1)
  button:hover:before {
  width: 8.9%;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(2) {
  background-color: white;
  text-align: right;
}

.AboutUs .Container .Mission .Container .col:nth-of-type(2) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.AboutUs .Container .Premium {
  width: 100%;
  height: 550px;
  position: relative;
}

.AboutUs .Container .Premium img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.AboutUs .Container .Premium .Text {
  margin-top: -350px;
  margin-left: 100px;
  color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.AboutUs .Container .Premium .Text h3 {
  font-size: 40px;
  padding-bottom: 20px;
}

.AboutUs .Container .Premium .Text p {
  font-weight: 550;
  color: white;
}

/* Small Screen Design Of The About Us Page */
@media (max-width: 995px) {
  .AboutUs .Container .Hero h1 {
    margin-left: 40px;
  }
  .AboutUs .Container .Banner .col img {
    width: 100%;
  }

  .AboutUs .Container .Banner {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    padding-top: 50px;
    padding-left: 20px;
    padding-right: 20px;
  }

  .AboutUs .Container .Banner .col img {
    width: 100%;
  }

  .AboutUs .Container .Statistics {
    height: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .AboutUs .Container .Statistics .Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
  }

  .AboutUs .Container .Mission {
    height: 100%;
    width: 100%;
  }

  .AboutUs .Container .Mission .Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    width: 100%;
    padding: 30px;
  }

  .AboutUs .Container .Premium .Text {
    margin-left: 30px;
  }
}

/* Contact Us Page Css */
.ContactUs {
  width: 100%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.ContactUs .Container .Hero {
  width: 100%;
  height: 400px;
}

.ContactUs .Container .Hero img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ContactUs .Container .Hero h3 {
  margin-top: -220px;
  margin-left: 100px;
  color: white;
  font-size: 30px;
}

.ContactUs .Container .Message {
  width: 100%;
  height: 700px;
}

.ContactUs .Container .Message .Container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 40px;
  width: 80%;
  height: 100%;
  margin: 0 auto;
  background-color: white;
  padding-top: 100px;
  border-bottom-style: solid;
  border-bottom-width: 1px;
  border-bottom-color: black;
}

.ContactUs .Container .Message .Container .col:nth-of-type(1) h3 {
  font-size: 30px;
}

.ContactUs .Container .Message .Container .col:nth-of-type(1) input {
  width: 90%;
  height: 50px;
  margin-top: 30px;
  padding-left: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  color: black;
  outline: black;
  border-radius: 10px;
  border-width: 1px;
}

.ContactUs .Container .Message .Container .col:nth-of-type(1) textarea {
  height: 200px;
  width: 90%;
  margin-top: 30px;
  border-radius: 10px;
  border-width: 1px;
  padding-top: 20px;
  padding-left: 10px;
  outline: black;
  line-height: 1.9;
}

.ContactUs .Container .Message .Container .col:nth-of-type(1) button {
  height: 60px;
  width: 200px;
  border-color: transparent;
  border-radius: 10px;
  position: relative;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-color: #f8bd32;
  font-size: 16px;
  margin-top: 30px;
}

.ContactUs .Container .Message .Container .col:nth-of-type(1) button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: -3px;
  left: 1px;
  width: 0%;
  height: 60px;
  background-color: #ffb4058d;
  transition: width 2s ease;
  border-radius: 10px;
}

.ContactUs
  .Container
  .Message
  .Container
  .col:nth-of-type(1)
  button:hover:before {
  width: 100%;
}

.ContactUs .Container .Message .Container .col:nth-of-type(2) h3 {
  font-size: 30px;
}

.ContactUs .Container .Message .Container .col:nth-of-type(2) .Contents {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-top: 30px;
}

.ContactUs .Container .Message .Container .col:nth-of-type(2) .Contents h5 {
  font-size: 22px;
  font-weight: 350;
  padding-bottom: 30px;
}

.ContactUs .Container .Message .Container .col:nth-of-type(2) .Contents li {
  list-style-type: none;
  font-size: 17px;
  padding-bottom: 10px;
}

.ContactUs
  .Container
  .Message
  .Container
  .col:nth-of-type(2)
  .Contents
  .Content1
  div {
  margin-top: 80px;
}

.ContactUs
  .Container
  .Message
  .Container
  .col:nth-of-type(2)
  .Contents
  .Content2
  div {
  margin-top: 80px;
}

.ContactUs .Container .More {
  margin-top: 100px;
  width: 100%;
  height: 400px;
  margin-bottom: 130px;
  display: none;
}

.ContactUs .Container .More .Container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  width: 80%;
  margin: 0 auto;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) {
  height: 350px;
  width: 100%;
  background-color: #edf9f9;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(198, 193, 193);
  padding-top: 40px;
  padding-left: 40px;
  border-radius: 10px;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) h3 {
  font-size: 30px;
  font-weight: 350;
  padding-bottom: 20px;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) p {
  font-size: 17px;
  padding-bottom: 30px;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) input {
  height: 50px;
  width: 75%;
  padding-left: 20px;
  border-width: 1px;
  border-color: black;
  outline: black;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border-right-color: transparent;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) button {
  height: 50px;
  width: 80px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border-width: 1px;
  font-size: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-color: #f17e52;
  color: white;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 1465px;
  left: 579px;
  width: 0%;
  height: 50px;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  background-color: #ed5f2775;
  transition: width 0.5s ease;
}

.ContactUs .Container .More .Container .col:nth-of-type(1) button:hover:before {
  width: 5.5%;
}

.ContactUs .Container .More .Container .col:nth-of-type(2) {
  height: 350px;
  width: 100%;
  background-color: #fbfbfb;
  border-style: solid;
  border-width: 1px;
  border-color: rgb(198, 193, 193);
  padding-top: 40px;
  padding-left: 40px;
  border-radius: 10px;
  line-height: 1.9;
}

.ContactUs .Container .More .Container .col:nth-of-type(2) h3 {
  font-size: 30px;
  font-weight: 350;
  padding-bottom: 20px;
}

.ContactUs .Container .More .Container .col:nth-of-type(2) p {
  font-size: 17px;
  padding-bottom: 30px;
}

.ContactUs .Container .More .Container .col:nth-of-type(2) button {
  height: 60px;
  width: 200px;
  border-radius: 10px;
  border-width: 1px;
  font-size: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  background-color: #f8bd32;
  color: black;
  border-color: transparent;
}

.ContactUs .Container .More .Container .col:nth-of-type(2) button:before {
  content: "";
  position: absolute;
  top: 1525px;
  left: 770px;
  width: 0%;
  height: 60px;
  border-radius: 10px;
  background-color: #fbb00298;
  transition: width 0.5s ease;
}

.ContactUs .Container .More .Container .col:nth-of-type(2) button:hover:before {
  width: 14%;
}

@media (max-width: 995px) {
  .ContactUs .Container .Message {
    height: 100%;
    padding-bottom: 40px;
  }

  .ContactUs .Container .Message .Container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 50px;
    padding-bottom: 30px;
  }

  .ContactUs .Container .Message .Container .col:nth-of-type(1) input {
    width: 100%;
  }

  .ContactUs .Container .Message .Container .col:nth-of-type(1) textarea {
    width: 100%;
  }

  .ContactUs .Container .Message .Container .col:nth-of-type(1) button {
    width: 100%;
  }
}

/* 404 Component Css */
.NotFound {
  width: 100%;
  /* height: 100%; */
}

.NotFound .Container img {
  width: 100%;
  /* height: 100%; */
  object-fit: cover;
}

.NotFound .Container .Message {
  width: 700px;
  height: 400px;
  background-color: rgba(255, 255, 255, 0.897);
  position: absolute;
  top: 350px;
  left: 0;
  right: 0;
  margin: 0 auto;
  text-align: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  padding-top: 110px;
  border-radius: 20px;
}

.NotFound .Container .Message h3 {
  font-size: 40px;
  font-weight: 400;
  padding-bottom: 10px;
}

.NotFound .Container .Message p {
  font-size: 18px;
}

.NotFound .Container .Message button {
  height: 70px;
  width: 240px;
  margin-top: 30px;
  border-color: transparent;
  border-radius: 10px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 450;
  background-color: #f8bd32;
}

.NotFound .Container .Message button .home {
  text-decoration: none;
  color: black;
  font-size: 16px;
  position: absolute;
  top: 240px;
  left: 290px;
}

.NotFound .Container .Message button:before {
  content: "";
  position: absolute;
  top: 217px;
  left: 230px;
  width: 0%;
  height: 70px;
  border-radius: 10px;
  background-color: #fbb00298;
  transition: width 0.5s ease;
}

.NotFound .Container .Message button:hover:before {
  width: 34%;
}

/* Style Of The NoteFound Page On Small Devices  */
@media (max-width: 995px) {
  .NotFound {
    width: 100%;
    height: 500px;
    /* margin: 0 auto; */
  }

  .NotFound .Container .Message {
    width: 90%;
    height: 60%;
    top: 110px;
    left: 0;
    right: 0;
    margin: 0 auto;
    padding-top: 10px;
    padding-left: 10px;
    padding-right: 10px;
    background-color: transparent;
    color: white;
  }

  .NotFound .Container .Message h3 {
    font-size: 25px;
    font-weight: 800;
  }

  .NotFound .Container .Message p {
    font-size: 16px;
  }

  .NotFound .Container .Message button {
    background-color: transparent;
  }

  .NotFound .Container .Message button .home {
    top: 160px;
    left: 0;
    right: 0;
    margin: 0 auto;
    color: white;
  }

  .NotFound .Container .Message button:before {
    top: 138px;
    left: 80px;
    background-color: transparent;
  }

  .NotFound .Container .Message button:hover:before {
    width: 60%;
  }
}

/* User Dashboard Css */

.UserDashboard .Container {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.UserDashboard .Container .Head {
  width: 100%;
  height: 330px;
  background-color: #f8bd32;
  /* background-color: rgba(0, 0, 0, 0.083); */
  padding-top: 120px;
  padding-left: 150px;
}

.UserDashboard .Container .Head h2 {
  font-size: 49px;
  font-weight: 650;
  padding-bottom: 20px;
}

.UserDashboard .Container .Head span p {
  font-size: 20px;
  font-size: 800;
}

.UserDashboard .Container .Head span p:nth-of-type(2) {
  margin-top: 20px;
}

.UserDashboard .Container .Head .Logs {
  float: right;
  margin-right: 250px;
}

.UserDashboard .Container .Head .Logs button {
  height: 50px;
  width: 100px;
  color: white;
  background-color: red;
  border-width: 1px;
  border-color: #000;
  border-radius: 10px;
  font-size: 15px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  position: absolute;
}

.UserDashboard .Container .Head .Logs button::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 0%;
  height: 50px;
  border-radius: 10px;
  /* background-color: rgba(255, 0, 0, 0.486); */
  background-color: rgba(200, 77, 77, 0.545);
  transition: width 0.5s ease;
}

.UserDashboard .Container .Head .Logs button:hover:before {
  width: 100%;
}

.UserDashboard .Container .Head .Logs button p {
  position: absolute;
  top: 15px;
  left: 20px;
}

.UserDashboard .Container .Authentication {
  width: 100%;
  height: auto;
  background-color: white;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  display: block;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding-top: 30px;
  padding-bottom: 30px;
}

.UserDashboard .Container .Authentication .SignIn {
  height: 550px;
  width: 30%;
  background-color: white;
  padding-top: 50px;
  border-color: rgb(222, 214, 214);
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  margin: auto;
}

.UserDashboard .Container .Authentication .SignIn h4 {
  font-size: 35px;
  padding-bottom: 30px;
}

.UserDashboard .Container .Authentication .SignIn input[type="email"],
.UserDashboard .Container .Authentication .SignIn input[type="password"] {
  width: 90%;
  height: 60px;
  margin-bottom: 20px;
  padding-left: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  outline: black;
  border-radius: 5px;
  border-width: 1px;
}

.UserDashboard .Container .Authentication .SignIn .Remember {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.UserDashboard .Container .Authentication .SignIn .Remember .Check {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-left: 22px; */
}

.UserDashboard .Container .Authentication .SignIn .Remember .Check p {
  padding-left: 10px;
  padding-right: 110px;
}

.UserDashboard .Container .Authentication .SignIn .Remember .Lost {
  font-weight: 650;
}

.UserDashboard .Container .Authentication .SignIn .Remember .Lost:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 645px;
  left: 870px;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.UserDashboard .Container .Authentication .SignIn .Remember .Lost:hover:before {
  width: 10%;
}

.UserDashboard .Container .Authentication .SignIn button {
  margin-top: 30px;
  width: 90%;
  height: 65px;
  border-color: transparent;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  margin-bottom: 20px;
  background-color: #f8bd32;
  border-radius: 5px;
}

.UserDashboard .Container .Authentication .SignIn button:before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 671px;
  left: 613.4px;
  width: 0%;
  height: 65px;
  background-color: #ffb405db;
  transition: width 2s ease;
  border-radius: 5px;
}

.UserDashboard .Container .Authentication .SignIn button p {
  font-size: 17px;
  position: absolute;
  top: 693px;
  left: 825px;
}

.UserDashboard .Container .Authentication .SignIn button:hover:before {
  width: 27%;
}

.UserDashboard .Container .Authentication .SignIn span {
  display: flex;
  align-items: center;
  padding-left: 50px;
  margin-top: 10px;
}

.UserDashboard .Container .Authentication .SignIn span p:nth-last-of-type(1) {
  padding-left: 170px;
  font-weight: 650;
}

.UserDashboard
  .Container
  .Authentication
  .SignIn
  span
  p:nth-last-of-type(1):before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 790px;
  left: 905px;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.UserDashboard
  .Container
  .Authentication
  .SignIn
  span
  p:nth-last-of-type(1):hover:before {
  width: 7%;
}

.UserDashboard .Container .Authentication .SignIn .UserSocials {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.UserDashboard .Container .Authentication .SignIn .UserSocials .Socials {
  font-size: 22px;
  margin: 20px;
  color: black;
}

.UserDashboard .Container .Authentication .SignUp {
  height: 910px;
  width: 30%;
  background-color: white;
  padding-top: 50px;
  border-color: rgb(222, 214, 214);
  border-width: 1px;
  border-radius: 5px;
  border-style: solid;
  margin: auto;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.UserDashboard .Container .Authentication .SignUp h4 {
  font-size: 30px;
  padding-bottom: 30px;
}

.UserDashboard .Container .Authentication .SignUp input {
  height: 60px;
  width: 90%;
  padding-left: 20px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  outline: black;
  margin-bottom: 20px;
  color: black;
  border-width: 1px;
  border-radius: 5px;
}

.UserDashboard .Container .Authentication .SignUp select {
  height: 60px;
  width: 90%;
  padding-left: 20px;
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 40px;
  outline: black;
}

.UserDashboard .Container .Authentication .SignUp .Instruction {
  text-align: left;
  padding-left: 25px;
  padding-bottom: 30px;
}

.UserDashboard .Container .Authentication .SignUp button {
  height: 65px;
  width: 90%;
  background-color: #f8bd32;
  text-align: center;
  border-color: transparent;
  border-radius: 5px;
  margin-bottom: 30px;
}

.UserDashboard .Container .Authentication .SignUp button p {
  font-size: 16px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: 350;
  position: absolute;
  top: 1100px;
  left: 820px;
}

.UserDashboard .Container .Authentication .SignUp button:before {
  content: "";
  position: absolute;
  top: 1078px;
  left: 615px;
  width: 0%;
  height: 65px;
  background-color: #ffb4058d;
  transition: width 2s ease;
  border-radius: 5px;
}

.UserDashboard .Container .Authentication .SignUp button:hover:before {
  width: 26.8%;
}

.UserDashboard .Container .Authentication .SignUp span {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  margin-top: 10px;
}

.UserDashboard .Container .Authentication .SignUp span p:nth-of-type(1) {
  padding-left: 20px;
}

.UserDashboard .Container .Authentication .SignUp span p:nth-of-type(2) {
  padding-left: 50px;
  font-weight: 650;
}

.UserDashboard
  .Container
  .Authentication
  .SignUp
  span
  p:nth-of-type(2)::before {
  content: "";
  position: absolute;
  /* bottom: -5px; */
  top: 1205px;
  left: 990px;
  width: 0%;
  height: 2px;
  background-color: #000;
  transition: width 0.5s ease;
}

.UserDashboard
  .Container
  .Authentication
  .SignUp
  span
  p:nth-of-type(2):hover:before {
  width: 3.4%;
}

.UserDashboard {
  background-color: var(--user-dashboard-background-color);
  color: var(--user-dashboard-text-color);
}

/* Shop Page Css */

.Shop {
  background-color: white;
}

.Shop .Content {
  width: 95%;
  height: 100%;
  background-color: red;
  margin: 100px auto;
}

/* Book Tour Css */

.BookTour {
  background-color: white;
}

.BookTour .Container .Coming-Soon {
  width: 100%;
  min-height: 500px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 30px;
}

/* Single Property Page Css */

.PropertyDetail {
  height: 100%;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.PropertyDetail .Container {
  margin: 0 auto;
  width: 98%;
}

.PropertyDetail .Container .Information {
  /* margin-top: 40px; */
  height: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  margin: 0 auto;
}

.PropertyDetail .Container .Information h1:nth-of-type(1) {
  font-size: 30px;
  font-weight: 200;
}

.PropertyDetail .Container .Information h1:nth-of-type(1) .Back-Link{
  text-decoration: none;
  color: black;
}

.PropertyDetail .Container .Information h1:nth-of-type(2) {
  font-size: 25px;
  font-weight: 200;
}

.PropertyDetail .Container .Carousel {
  width: 90%;
  height: 100%;
  margin: 0 auto;
}

.PropertyDetail .Container .Carousel .Carousel-Wrapper {
  height: 100%;
  /* position: relative; */
}

.PropertyDetail .Container .Carousel .Carousel-Wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.PropertyDetail .Container .Description {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 30px;
  margin-bottom: 30px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.PropertyDetail .Container .Description h1{
  padding-bottom: 30px;
  font-weight: 500;
}

.PropertyDetail .Container .Description p{
  line-height: 1.6;
}


.PropertyDetail .Container .Features {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.PropertyDetail .Container .Features h1{
  padding-bottom: 20px;
  font-weight: 500;
}

.PropertyDetail .Container .Features p{
  line-height: 1.6;
}

.PropertyDetail .Container .Features .Details{
  border-top-style: solid;
  border-top-color: black;
  border-top-width: 1px;
  margin-top: 30px;
  padding-top: 50px;
}

.PropertyDetail .Container .Videos {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.PropertyDetail .Container .Videos h1{
  padding-bottom: 20px;
  font-weight: 500;
}

.PropertyDetail .Container .Videos .video-player {
  width: 100%;        /* Set width to 80% of the parent container */
  height: 600px;     /* Set height to 400px */
  object-fit: cover; /* Ensure the video covers the container without stretching */
  display: block;   /* Make sure the video is displayed as a block element */
  border-radius: 5px;
}

.PropertyDetail .Container .Location {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.PropertyDetail .Container .Location h1{
  padding-bottom: 20px;
  font-weight: 500;
}

.PropertyDetail .Container .Inquiries {
  width: 90%;
  height: 100%;
  margin: 0 auto;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-bottom: 30px;
  border-bottom-style: solid;
  border-bottom-color: black;
  border-bottom-width: 1px;
}

.PropertyDetail .Container .Inquiries h1{
  padding-bottom: 20px;
  font-weight: 500;
}

.PropertyDetail .Container .Inquiries .Button button{
  width: fit-content;
  height: 50px;
  text-align: start;
  padding-left: 30px;  
  padding-right: 30px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-size: 16px;
  border-radius: 10px;
  border-color: transparent;
  background-color: black;
  color: white;

}


/* @media (max-width: 1184px) {
.PropertyDetail .Container .Inquiries .Button button{
}

} */

/* Light & Dark Theme Css */
:root {
  --user-dashboard-background-color: #ffffff;
  --user-dashboard-text-color: #000000;
}

/* Dark Theme */
@media (prefers-color-scheme: dark) {
  :root {
    /* --user-dashboard-background-color: #a87703; */
    --user-dashboard-background-color: #000000;
    --user-dashboard-text-color: #ffffff;
    /* --user-dashboard-authentication-background-color: #edcf87f6; */
    --user-dashboard-authentication-background-color: #000000f6;
    /* --user-dashboard-login-button-before-background-color: #c99725; */
    --user-dashboard-login-button-before-background-color: #222221;
    --user-dashboard-signin-h4-text-color: #000000;
    --user-dashboard-signup-instruction-text-color: #000000;
    --user-dashboard-footer-background-color: #000000f6;
  }

  .UserDashboard .Container .Head {
    background-color: var(--user-dashboard-background-color);
    border-bottom-style: solid;
    border-bottom-width: 0.5px;
    border-bottom-color: white;
  }

  .UserDashboard .Container .Authentication {
    background-color: var(--user-dashboard-authentication-background-color);
  }

  .UserDashboard .Container .Authentication .SignIn h4 {
    color: var(--user-dashboard-signin-h4-text-color);
  }

  .UserDashboard .Container .Authentication .SignIn span p {
    color: var(--user-dashboard-signin-h4-text-color);
  }

  .UserDashboard .Container .Authentication .SignIn button {
    background-color: var(--user-dashboard-background-color);
    color: var(--user-dashboard-text-color);
  }

  .UserDashboard .Container .Authentication .SignIn button:before {
    background-color: var(
      --user-dashboard-login-button-before-background-color
    );
  }

  /* User Dashboard Sign Up DarkMode  */
  .UserDashboard .Container .Authentication .SignUp h4 {
    color: var(--user-dashboard-signin-h4-text-color);
  }

  .UserDashboard .Container .Authentication .SignUp span p {
    color: var(--user-dashboard-signin-h4-text-color);
  }

  .UserDashboard .Container .Authentication .SignUp button {
    background-color: var(--user-dashboard-background-color);
    color: var(--user-dashboard-text-color);
  }

  .UserDashboard .Container .Authentication .SignUp button:before {
    background-color: var(
      --user-dashboard-login-button-before-background-color
    );
  }

  .UserDashboard .Container .Authentication .SignUp .Instruction {
    color: var(--user-dashboard-signup-instruction-text-color);
  }

  /* .Footer {
    background-color: var(--user-dashboard-footer-background-color);
    border-top-style: solid;
    border-top-width: 0.5px;
    border-top-color: white;
  } */
  /*   
  .Footer .Bottom{
    background-color: var(--user-dashboard-footer-background-color);
  } */
}
